import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
    organized: [
    {
        "category": {
            "id": 1,
            "name": "Vins rouges"
        },
        "products": [
            {
                "id": 1,
                "category": {
                    "id": 1,
                    "name": "Vins rouges"
                },
                "name": "Miros Ribera del Duero Riserva",
                "description": "Couleur rouge rubis limpide. Nez de fruits mûrs et subtils arômes de torréfaction épicée.\r\nEn bouche, il est corsé avec des arômes variés et des tanins mûrs.\r\nLa finale est longue avec une parfaite harmonie entre le fruit et les tanins.",
                "ingredients": "",
                "preservation": "",
                "vinification": "Vieillissement 24 mois en fûts de chêne français.",
                "image": "/images/wine_4.jpg",
                "price": "37.00"
            },
            {
                "id": 3,
                "category": {
                    "id": 1,
                    "name": "Vins rouges"
                },
                "name": "Les Sorts Sycar",
                "description": "Rouge cerise foncé; Arômes profonds et très expressifs de violette, de fruits rouges mûrs et de prune. Des notes épicées, un peu de tabac et de cacao peuvent également être dégustées sur des arômes torréfiés ; doux mais plein de caractère avec des tanins doux, fruité avec des notes claires.",
                "ingredients": "",
                "preservation": "",
                "vinification": "Elevage en barriques françaises (90%) et américaines (10%) pendant 14 mois.",
                "image": "/images/wine_3.jpg",
                "price": "21.50"
            },
            {
                "id": 5,
                "category": {
                    "id": 1,
                    "name": "Vins rouges"
                },
                "name": "Raasol Veneto",
                "description": "Ravazzol est l'endroit où se trouve Bodega Contr'malini et \"Raasel\" est la façon dont le nom se prononce dans le dialecte local. Ce vin vise donc à rendre hommage aux origines, à partir des cépages locaux.",
                "ingredients": "",
                "preservation": "",
                "vinification": "Après la récolte, les raisins sont séchés dans des endroits bien ventilés jusqu'à fin janvier.\r\nIl est élevé en fûts et en barriques de chêne slovène pendant 48 mois, suivi de quelques mois en bouteille.",
                "image": "/images/wine_2.jpg",
                "price": "30.00"
            },
            {
                "id": 4,
                "category": {
                    "id": 1,
                    "name": "Vins rouges"
                },
                "name": "Feritas Veneto Corte Altar",
                "description": "Rouge rubis aux reflets violets; bouquet épicé aux arômes de fruits mûrs; chaleureux et élégant en bouche; Le goût rappelle celui d'un Amarone.",
                "ingredients": "",
                "preservation": "",
                "vinification": "Semblable à l'Amarone, les raisins de Feritas sont séchés dans des caisses, mais contrairement à l'Amarone, ils ne sont séchés que pendant 40 jours avant d'être transformés. Elevage 12 mois en barriques françaises et 6 mois en bouteille.",
                "image": "/images/wine_1.jpg",
                "price": "25.00"
            }
        ]
    },
    {
        "category": {
            "id": 2,
            "name": "Autres"
        },
        "products": [
            {
                "id": 8,
                "category": {
                    "id": 2,
                    "name": "Autres"
                },
                "name": "Miel du Jorat, Orvin (250gr.)",
                "description": "Excellent miel des abeilles de notre région fait par la famille Aufranc Ludovic et Tabea.",
                "ingredients": "",
                "preservation": "2 ans, température ambiante.",
                "vinification": "",
                "image": "/images/miel-jorat.png",
                "price": "9.00"
            }
        ]
    },
    {
        "category": {
            "id": 3,
            "name": "Apéritifs"
        },
        "products": [
            {
                "id": 6,
                "category": {
                    "id": 3,
                    "name": "Apéritifs"
                },
                "name": "Zucchine à l’aigre-doux (250gr.)",
                "description": "Ces courgettes à l’aigre-doux, fait par nos soins, se marient très bien avec la raclette ou simplement pour l’apéro.",
                "ingredients": "Courgettes, oignons, vinaigre, sucre brun, eau, vin blanc, curry, moutarde en grains, sel.",
                "preservation": "6 mois, maintenir au réfrigérateur après ouverture.",
                "vinification": "",
                "image": "/images/courgettes_txzlLBO.jpg",
                "price": "9.00"
            },
            {
                "id": 7,
                "category": {
                    "id": 3,
                    "name": "Apéritifs"
                },
                "name": "Caviar d’aubergines (250gr.)",
                "description": "Ce caviar d’aubergines, fait par nos soins, se tartine sur un pain croustillant et se déguste à l’apéro.",
                "ingredients": "aubergines, menthe, ail, citron, huile d’olives, sel, poivre.",
                "preservation": "6 mois, maintenir au réfrigérateur après ouverture.",
                "vinification": "",
                "image": "/images/caviar-aubergines.png",
                "price": "10.00"
            }
        ]
    }
],
    status: 'idle',
    error: null
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
})

export default productsSlice.reducer
