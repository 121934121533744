import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const ScrollToTop = lazy(() => import('./utils/ScrollToTop'));
const AlwaysOnTop = lazy(() => import('./utils/AlwaysOnTop'));
const Footer = lazy(() => import('./layout/Footer'));
const TopBar = lazy(() => import('./layout/TopBar'));
const HomePage = lazy(() => import('./pages/HomePage'));
const ProductsPage = lazy(() => import('./pages/ProductsPage'));

function App() {
  return (
    <div className="App">
	  <Router>
		<Suspense fallback={<h1>chargement ...</h1>}>
			<TopBar />
			<AlwaysOnTop />
				<Routes>
					<Route exact path="/" element={<HomePage />} />
					<Route exact path="/vins-et-produits/" element={<ProductsPage />} />
				</Routes>
			<Footer />
			<ScrollToTop />
		</Suspense>
	  </Router>
    </div>
  );
}

export default App;
